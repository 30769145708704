import React, { useEffect } from 'react'
import { Layout, Stack, Hero, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Text, Card, Flex, Heading } from 'theme-ui'
import Section from '@components/Section'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getStaticPageSeoDetails } from '@utils-blog'
import { PostBody } from '@widgets/Post'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import './OtherResources.css'


const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  boxs: {
    display: `flex`,
    flexWrap: `wrap`
  },
  box: {
    flex: `0 0 33.33%`,
    p: 20,
    '> div': {
      height: '100%'
    }
  },
  buttons: {
    m: 1
  },
  wrapper: {
    textAlign: `center`
  },
  avatarWrapper: {
    mb: 4
  },
  title: {
    color: `omegaDark`
  },
  des: {
    textAlign: 'left',
    lineHeight: '1.5'
  },
  linkColor: {
    a: {
      color: '#667eea',
      textDecoration: 'none',
      '&:hover': {
        color: '#5a67d8'
      }
    }
  }
}
const OtherResource = props => {
  const { allStrapiOtherResource } = useStaticQuery(OtherResourceQuery)

  const allResource = allStrapiOtherResource.nodes
  const bodyDetail = {
    bodyContent: allResource[0].sectionDetail.data.sectionDetail
  }

  let seoData = getStaticPageSeoDetails('shared.other-resources')

  const otherResourceSeoData = {
    ...seoData,
    Other_Resources: {
      ...seoData?.Other_Resources,
      keywords: seoData?.Other_Resources?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={otherResourceSeoData?.Other_Resources?.metaTitle}
        description={otherResourceSeoData?.Other_Resources?.metaDescription}
        keywords={otherResourceSeoData?.Other_Resources?.keywords}
        thumbnail={otherResourceSeoData?.Other_Resources?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'otherResources'}
            {...props}
            callFrom='Other Resources'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Box
            sx={styles.linkColor}
            dangerouslySetInnerHTML={{ __html: bodyDetail?.bodyContent }}
          ></Box>
        </Main>
      </Stack>
    </Layout>
  )
}
export default OtherResource

const OtherResourceQuery = graphql`
  query {
    allStrapiOtherResource {
      nodes {
        sectionDetail {
          data {
            sectionDetail
          }
        }
        sectionImage {
          localFile {
            childImageSharp {
              ImageSharp_vertical: gatsbyImageData(
                transformOptions: { cropFocus: CENTER }
                outputPixelDensities: [0.5, 1]
              )
            }
          }
        }
      }
    }
  }
`
